import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

//components
import { slide as Menu } from 'react-burger-menu'
import Button from '../Button/button'

// utils
import {BRAND_LOGO} from '../../utils/constants'

class Topbar extends React.Component {
    //constructor to set state and bind "this"
    constructor(props){
        super(props)
        this.state = {
            isNavBarTop: true
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        const windowTop = window.scrollY < 400
        windowTop ? this.setState({isNavBarTop: true}) : this.setState({isNavBarTop: false}) 
    }

    __renderNavigationLinks (classes) {
        return <ul className={` ${classes}`}>
            <li className='nav-item '>
                <Button classes='btn btn-link py-3 px-0 my-2 my-lg-0 px-lg-3' isInternal to='/resources/' event={'website_notes_button_clicked'} eventValues={{location: 'Header - Navbar'}}>
                    {/* Desktop */}
                    <span>Resources</span>
                </Button>
            </li>
            <li className='nav-item '>
                <Button classes='btn btn-link py-3 px-0 my-2 my-lg-0 px-lg-3' isInternal to='/parents/' event={'website_parents_button_clicked'} eventValues={{location: 'Header - Navbar'}}>
                    {/* Desktop */}
                    <span>Parents</span>
                </Button>
            </li>
            <li className='nav-item '>
                <Button classes='btn btn-link py-3 px-0 my-2 my-lg-0 px-lg-3' isInternal to='/pricing/' event={'website_pricing_button_clicked'} eventValues={{location: 'Header - Navbar'}}>
                    {/* Desktop */}
                    <span>Pricing</span>
                </Button>
            </li>
            <li className='nav-item '>
                <Button classes='btn btn-link py-3 px-0 my-2 my-lg-0 px-lg-3' isInternal to='/faq/' event={'website_faq_button_clicked'} eventValues={{location: 'Header - Navbar'}}>
                    {/* Desktop */}
                    <span>FAQ</span>
                </Button>
            </li>
            <li className='nav-item '>
                <Button classes='btn btn-link py-3 px-0 px-lg-3 my-2 my-lg-0' isInternal to='/guarantee/' event={'website_guarantee_button_clicked'} eventValues={{location: 'Header - Navbar'}}>
                    {/* Desktop */}
                    <span>A Guarantee</span>
                </Button>
            </li>
            <li className='nav-item '>
                <Button classes='btn btn-link py-3 px-0 px-lg-3 my-2 my-lg-0' isInternal to='/how-it-works/' event={'website_learn_more_button_clicked'} eventValues={{location: 'Header - Navbar'}}>
                    {/* Desktop */}
                    <span>How it works</span>
                </Button>
            </li>
            <li className='nav-item align-items-center d-flex mb-4 my-lg-3 my-xl-0'>
                <Button primary classes='mx-0 mx-md-3 py-2'>Try for free</Button>
            </li>
            <li className='nav-item align-items-center d-flex my-4 my-lg-0'>
                <a className='btn btn-outline-primary py-2' href='https://app.quicksense.org'>Login</a>
            </li>
        </ul>
    }

    render() {
        return <>
            <header className={`header pt-2 ${this.props.type && this.state.isNavBarTop ? '' : '--floating'}`}>
                <div className={` ${this.props.hideNavigation ? 'col-12' : 'col col-sm-6 col-md-4 col-lg-3'} d-flex align-items-center`}>
                    <span dangerouslySetInnerHTML={{__html: BRAND_LOGO}}></span> 
                    <Link classes='btn-link --underline-none' to={this.props.hideNavigation ? this.props.directPageDestinationUrl : '/'}><h1 className={'h4 mb-0 py-1 pl-2 text-center text-sm-left'}>QuickSense</h1></Link>
                </div>
                {
                    !this.props.hideNavigation &&  <nav className='col d-flex align-items-center justify-content-end p-0 d-none d-xl-flex'>
                        {this.__renderNavigationLinks('nav d-none d-xl-flex')}
                    </nav>
                }

            </header>
            {
                !this.props.hideNavigation && <Menu pageWrapId={ 'page-wrap' } outerContainerId={ 'outer-container' } disableAutoFocus burgerButtonClassName={'d-block d-xl-none'}>
                    {this.__renderNavigationLinks('')}
                </Menu>
            }                    

        </>
    }
}

Topbar.propTypes = {
    type: PropTypes.string,
    hideNavigation: PropTypes.bool,
    directPageDestinationUrl: PropTypes.string
}

Topbar.defaultProps = {
    hideNavigation: false,
    directPageDestinationUrl: '/parents/'
}

export default Topbar