import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

class Button extends React.Component {

    handleSendEvent = (event, values) => {
        window.analytics.track(event, values) 
    }

    renderInteralButton(){
        return <Link
            to={this.props.to}
            onClick={() => {this.handleSendEvent(this.props.event, this.props.eventValues)}}
            className={`
              ${this.props.primary ? 'btn btn-primary' : ''} 
              ${this.props.secondary ? 'btn btn-outline-primary' : ''} 
              ${this.props.link ? 'btn btn-link' : ''} 
              ${this.props.classes}  ${this.props.className}`}>
            {this.props.children ? this.props.children : this.props.content}
        </Link>
    }

    renderExternalButton(){
        return <a
            href={this.props.href}
            target={this.props.target}
            onClick={() => {this.handleSendEvent(this.props.event, this.props.eventValues)}}
            rel={this.props.rel}
            className={`
              ${this.props.primary ? 'btn btn-primary' : ''} 
              ${this.props.secondary ? 'btn btn-outline-primary' : ''} 
              ${this.props.link ? 'btn btn-link' : ''} 
              ${this.props.classes} ${this.props.className}`}>
            {this.props.children ? this.props.children : this.props.content}
        </a>
    }

    render(){
        return <>{
            (this.props.isInternal || this.props.to)
                ? this.renderInteralButton()
                : this.renderExternalButton()
        }
        </>
    }
}

Button.propTypes = {
    children: PropTypes.node,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    link: PropTypes.bool,
    isInternal: PropTypes.bool,
    type: PropTypes.string,
    event: PropTypes.string,
    eventValues: PropTypes.object,
    content: PropTypes.string,
    target: PropTypes.string,
    to: PropTypes.string,
    rel: PropTypes.string,
    href: PropTypes.string,
    classes: PropTypes.string,
    className: PropTypes.string,
    click: PropTypes.func,
    isLocal: PropTypes.bool,
}

Button.defaultProps = {
    isInternal: false,
    eventValues: {
        'location': 'Hero Page'
    },
    target: '_self',
    event: 'website_sign_up_button_clicked',
    content: 'Try for free',
    href: 'https://app.quicksense.org/signup',
}

export default Button