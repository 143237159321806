import React from 'react'
import PropTypes  from 'prop-types'
import { Link } from 'gatsby'

//Bootstrap Components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//Components
import Button from '../Button/button'

//Styling
import {footer, footerSecondary, categoryNav, categoryTitle, categoryList} from './footer.module.scss'

const BRAND_NAME = 'QUICKSENSE'

const FooterCategories = ({category}) => {
    return(
        <nav className={categoryNav}>
            <h3 className={` ${categoryTitle} h3`}>{category.title}</h3>
            <ul className={categoryList}>
                {category.links.map((link, index) => (
                    <li key={index}>
                        {
                            link.type == 'social' ?
                                <div className='d-flex justify-content-center'>
                                    {
                                        link.socials.map((val, index) => (
                                            <a href={val.url} className={`p-2 mx-1 text-white`} key={index}> 
                                                <img height={32} width={32} className='lazyload' loading={'lazy'} alt={`${val.type} icon`} data-src={val.icon}/>
                                                <noscript>
                                                    <img height={32} width={32} alt={`${val.type} icon`} loading={'lazy'} src={val.icon}/>
                                                </noscript>
                                            </a>
                                        ))
                                    }
                                </div>:
                                <Button to={link.link} link classes='text-white text-small'>{link.title}</Button>
                        }
                    </li>
                ))}
            </ul>
        </nav>
    )
}

const CATEGORY_DATA = [
    {
        title: 'Why QuickSense',
        links: [
            {
                title:'A* Guarantee',
                link: '/guarantee/',
                type: 'internal',
                event: 'website_guarantee_button_clicked',
                eventValues: {
                    location: 'Footer'
                }
            },
            {
                title: 'How it works',
                link: '/how-it-works/',
                type: 'internal',
                event: 'website_learn_more_button_clicked',
                eventValues: {
                    location: 'Footer'
                }
            },
            {
                title: 'About Us',
                link: '/about/',
                type: 'internal',
                event: 'website_about_button_clicked',
                eventValues: {
                    location: 'Footer'
                }
            }
        ]
    },
    {
        title: 'Resources',
        links: [
            {
                title: 'Pricing',
                link: '/pricing/',
                type: 'internal',
                event: 'website_pricing_button_clicked',
                eventValues: {
                    location: 'Footer'
                }
            },
            {
                title: 'FAQ',
                link: '/faq/',
                type: 'internal',
                event: 'website_faq_button_clicked',
                eventValues: {
                    location: 'Footer'
                }
            },
            {
                title: 'Contact us',
                link: '/contact/',
                type: 'internal',
                event: 'website_contact_button_clicked',
                eventValues: {
                    location: 'Footer'
                }
            },
        ]
    },
    {
        title: 'Terms',
        links: [

            {
                title: 'Terms of Service',
                link: '/terms/',
                type: 'internal'
            },
            {
                title: 'Privacy Policy',
                link: '/privacy/',
                type: 'internal'
            },
            {
                title: 'Cookies',
                link: '/cookies/',
                type: 'internal'
            }
        ]
    }
]

class Footer extends React.Component {
    render () {
        let today = new Date()
        let year = today.getFullYear()

        return <footer className={footer}>
            {
                this.props.hideNavigation ? 
                    null :
                    <Container>
                        <Row>
                            <Col as='section' lg={12}>
                                <Link to={this.props.directPageDestinationUrl ? this.props.directPageDestinationUrl : '/'}><h2 className='fw-brand text-center mb-5 text-white' style={{fontSize: '1.5rem'}}>{BRAND_NAME}</h2></Link>
                            </Col>
                            {CATEGORY_DATA.map((category, index) => (
                                <Col xs={12} sm={4} lg={4} className='col-12 col-sm-4 col-lg-4' key={index} > 
                                    <FooterCategories category={category}/>
                                </Col>
                            ))}
                        </Row>
                    </Container>
            }

            <div className={footerSecondary}>
                <h2 className='h5 text-center'>© {year}, {BRAND_NAME} EDUCATION</h2>
            </div>
        </footer>
    }
}

export default Footer

FooterCategories.propTypes = {
    category: PropTypes.object,
}

Footer.propTypes = {
    directPageDestinationUrl: PropTypes.string,
    hideNavigation: PropTypes.bool
}