import React from 'react'
import PropTypes from 'prop-types'

//Lazysizes Component
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

//styles
import {main} from './layout.module.scss'

//components
import Topbar from '../Topbar/topbar'
import Footer from '../Footer/footer'

class Layout extends React.Component {

    componentDidMount() {
        //Facebook Messenger Component
        var chatbox = document.getElementById('fb-customer-chat')
        chatbox.setAttribute('page_id', '1802538543407665')
        chatbox.setAttribute('attribution', 'biz_inbox')

        window.fbAsyncInit = function() {
            // eslint-disable-next-line no-undef
            FB.init({
                xfbml            : true,
                version          : 'v11.0',
                minimized        : true
            })
        };

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return
            js = d.createElement(s); js.id = id
            js.src = 'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js'
            fjs.parentNode.insertBefore(js, fjs)
        }(document, 'script', 'facebook-jssdk'))
    }

    render() {
        return <>
            <Topbar type={this.props.type} directPageDestinationUrl={this.props.focusedModeURL} hideNavigation={this.props.focusedMode} />
            <main className={`${main} ${this.props.classes}`}>
                {this.props.children}
                <div id='fb-root'></div>
                <div id='fb-customer-chat' className='fb-customerchat'></div>
            </main>
            <Footer hideNavigation={this.props.focusedMode} directPageDestinationUrl={this.props.focusedModeURL}/>
            <noscript>
                <style>
                    {`
                        [data-sal|=fade] {
                            opacity: 1;
                        }

                        [data-sal|=slide],
                        [data-sal|=zoom] {
                            opacity: 1;
                            transform: none;
                        }

                        [data-sal|=flip] {
                            transform: none;
                        }
                        
                        .no-js img.lazyload {
                            display: none;
                          }
                    `}
                </style>
            </noscript>
        </>
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    type: PropTypes.string,
    focusedMode: PropTypes.bool,
    focusedModeURL: PropTypes.string,
}

export default Layout